.App {
  text-align: center;

  font-family: 'Lato', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Co-logo {
  height: 200px;
  pointer-events: none;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  margin: 20px;
}

.App-header {
  
}

.App-link {
  color: #61dafb;
}

.sky{
  background: #5DA7FC;
  padding: 20px;
  border-radius: 20px;
}

body{
  max-width: 1024px;
  margin: auto;
  background-color: none;
  height: 24;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  font-size: calc(10px + 2vmin);
  color: white;
  text-shadow: 1px 1px 0px #333, 0px 0px 1px #333;
}

a{
  color: white;
  margin: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
}


html{ 
  background: url(./images/backgroundimage.png) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}